import Vue from 'vue';
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import Vuetify from 'vuetify'
import locale from 'element-ui/lib/locale/lang/en'
import BlockUIService from './Shared/BlockUIService.js';
import App from './App.vue';
import Layout from './components/Layout/Layout.vue';
import Login from './components/Login/Login.vue';
import Home from './components/Home/Home.vue';
import DataService from './Shared/DataService';
import Helper from './Shared/Helper';

import Users from './components/Users/Users.vue';
import Profile from './components/Users/EditUsersProfile/EditUsersProfile.vue'



import AddMessages from "./components/Messages/Add/Add.vue";
import AddMessagesOut from "./components/Messages/AddOut/Add.vue";
import AddInboxOut from "./components/Messages/AddInboxOut/Add.vue";
import Sender from "./components/Messages/Sender/Messages.vue";
import SenderOut from "./components/Messages/Sender/SenderOut/Messages.vue";
import Inbox from "./components/Messages/Inbox/Messages.vue";
import InboxOut from "./components/Messages/Inbox/InboxOut/Messages.vue";

import Files from "./components/Files/Files.vue";
import AddFiles from "./components/Files/Add/Add.vue";

//import Prisoners from "./components/Messages/Prisoners.vue";
//import PatientTransacitons from "./components/Messages/Transactions/Transactions.vue";
//import PatientTransfer from './components/Messages/TransferRequest/TransferInfo.vue';
//import PatientTransferRequest from './components/Messages/TransferRequest/Add/TransferRequest.vue';
//import PatientTransferRequestCenter from './components/Messages/TransferRequest/CenterChangeRequest/TransferRequest.vue';
//import PatientDailyUsed from "./components/Messages/DailyUsed/DailyUsed.vue";
//import PatientDailyUsedReport from "./components/Messages/DailyUsedReport/DailyUsedReport.vue";
//import Required from "./components/Messages/DailyUsedRequired/DailyUsedRequired.vue";


//import company from './components/company/company.vue';
//import Devices from './components/company/Devices/Devices.vue';
//import Filters from './components/company/Filters/Filters.vue'; 

import Offices from './components/Dictionaries/Offices/Offices.vue';







import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueEllipseProgress);

Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

Vue.prototype.$http = DataService;
Vue.prototype.$blockUI = BlockUIService;
Vue.prototype.$helper = Helper;

export const eventBus = new Vue();

//const i18n = new VueI18n({
//    locale: 'ar', // set locale
//    messages, // set locale messages
//})

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    linkActiveClass: "active",
    routes: [
        {
            path: "/Login",
            component: Login,
        },
        {
            path: "/",
            component: App,
            children: [
                {
                    path: "",
                    component: Layout,
                    children: [
                        { path: "", component: Home },
                      /*  { path: "Prisoners", component: Prisoners },*/
                        { path: "AddMessages", component: AddMessages },
                        { path: "AddMessagesOut", component: AddMessagesOut },
                        { path: "AddInboxOut", component: AddInboxOut },
                        { path: "Sender", component: Sender },
                        { path: "SenderOut", component: SenderOut },
                        { path: "Inbox", component: Inbox },
                        { path: "InboxOut", component: InboxOut },

                        { path: "Files", component: Files },
                        { path: "AddFiles", component: AddFiles },
                        //{ path: "PatientTransacitons", component: PatientTransacitons },
                        //{ path: "PatientTransfer", component: PatientTransfer },
                        //{ path: "PatientTransferRequest", component: PatientTransferRequest },
                        //{ path: "PatientTransferRequestCenter", component: PatientTransferRequestCenter },
                        //{ path: "PatientDailyUsed", component: PatientDailyUsed },
                        //{ path: "PatientDailyUsedReport", component: PatientDailyUsedReport },
                        //{ path: "Required", component: Required, },

                        //{ path: "company", component: company },
                        //{ path: "Devices", component: Devices },
                        //{ path: "Filters", component: Filters },

                        { path: "Offices", component: Offices },

                        { path: "Users", component: Users },
                        { path: "Profile", component: Profile },

                        
                    ],
                },
            ],
        },
    ],
});

Vue.filter("toUpperCase", function (value) {
    if (!value) return "";
    return value.toUpperCase();
});

new Vue({
    router,
    render: (h) => {
        return h(App);
    },
}).$mount("#cpanel-management");
